.app-bar-toolbar {
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0 !important;
  height: 64px;

  .app-bar-toolbar-root {
    color: #003555;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    .app-bar-menu-mobile {
      width: 70%;
      text-align: end;
    }

    .app-bar-menu-desktop {
      display: none;
    }
  }
}

.menu-item-container div ul li {
  a {
    text-decoration: none;
    color: #003555;
  }
}

@media (min-width: 1200px) {
  .app-bar-toolbar {
    .app-bar-toolbar-root {
      .app-bar-menu-mobile {
        display: none;
      }

      .app-bar-menu-desktop {
        width: 50%;
        display: flex;
        gap: 5px;
        justify-content: space-evenly;
        text-align: center;

        > a {
          text-decoration: none;
          padding: 10px 0px;
          width: 100%;
          color: #003555;
          font-size: 18px;
          height: 64px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          align-content: center;
          transition: all 0.5s ease-in-out;
        }

        > a:hover {
          cursor: pointer;
          background-color: #e7f7ff;
        }

        > a:active {
          background-color: #ccefff;
        }

        .active {
          border-top: 5px solid #ccefff;
          border-bottom: 5px solid #ccefff;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
}
